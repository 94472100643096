import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { Grid, Container, Typography, Box } from "@mui/material";
import ISO from "../../assets/images/iso.png";
import Innova from "../../assets/images/innova.png";
import IconMail from "../../assets/images/icon-mail.svg";
import IconPhone from "../../assets/images/icon-phone.svg";
import Angeco from "../../assets/images/angeco.png";
import gPartners from "../../assets/images/gPartners.jpg";
import Pci from "../../assets/images/pci.png";
import IconLinkedin from "../../assets/images/icon-linkedin.png";
import IconTwitter from "../../assets/images/icon-twitter.png";
import Cookies from "js-cookie";
import { TraduccionesContext } from "../shared/TraduccionesContext";
import SeoRoutesContext from "../shared/SeoRoutesContext"; // Import the custom hook

export const FrontOfficeFooter = () => {
  const traducciones = useContext(TraduccionesContext);
  const seoRoutes = useContext(SeoRoutesContext); // Correct use of useContext
  const navigateTo = (routeKey, seoRoutes, lang) => {
    let path = "";
    const seoEntry = seoRoutes.find(
      (seo) =>
        seo.PAGE.replaceAll(".aspx", "").toLowerCase() === routeKey.toLowerCase() &&
        seo.ACRONIMO_ISO === lang
    );
    if (seoEntry) {
      path = `/${seoEntry.ACRONIMO_ISO}/${seoEntry.SEO_URL_FRIENDLY}`;
    } else {
      path = routeKey;
    }
    path = path.replace("//", "/");
    return path;
  };

  const handleNavigation = (key) => {
    const pathSegments = location.pathname.split("/").filter(Boolean);
    const lang = pathSegments[0] || "es"; // Asumir "es" como idioma por defecto si no se encuentra ninguno
    Cookies.set("lang", lang, { expires: 7 });
    const path = navigateTo(key, seoRoutes, lang);
    return path + "/"; // Not typically needed unless you have other uses for path after navigation
  };

  const [hasCookie, setHasCookie] = useState(false);
  const [cookieValue, setCookieValue] = useState(Cookies.get("cookieValue")); // Initialize with the current cookie value
  useEffect(() => {
    // Update hasCookie based on the presence of cookieValue
    setHasCookie(!!cookieValue);
  }, [cookieValue]); // Dependency on cookieValue to trigger re-evaluation

  return (
    <>
      <Box
        display="flex"
        sx={{
          borderTop: "1px solid #A24B5D",
          backgroundColor: "#FFF",
          justifyContent: "center",
          alignItems: "center",
          maxWidth: "100%",
          "overflow-x": "hidden",
          "& *": { fontFamily: "Nunito !important" },
        }}
      >
        <Container sx={{ textAlign: "center" }}>
          <Grid container sx={{ paddingY: "4rem", paddingBottom: "0" }}>
            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              lg={3}
              xl={3}
              sx={{
                marginBottom: "2rem",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: {
                    xs: "center",
                    sm: "center",
                    md: "start",
                    lg: "start",
                    xl: "start",
                  },
                  width: "100%",
                  alignItems: "center",
                  gap: ".5rem",
                  marginBottom: "0.5rem",
                }}
              >
                <img src={IconMail} alt="email" />
                <Link
                  style={{ fontWeight: "bold", fontSize: "19px", color: "#000000de" }}
                  to={
                    traducciones.traducciones &&
                    "mailto:" + traducciones.traducciones.CONTACTO.contact_email
                  }
                >
                  {traducciones.traducciones && traducciones.traducciones.CONTACTO.contact_email}
                </Link>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: {
                    xs: "center",
                    sm: "center",
                    md: "start",
                    lg: "start",
                    xl: "start",
                  },
                  width: "100%",
                  alignItems: "center",
                  gap: ".5rem",
                  marginBottom: "0.5rem",
                }}
              >
                <img src={IconPhone} alt="phone" />
                <Link
                  style={{ fontWeight: "bold", fontSize: "19px", color: "#000000de" }}
                  to={
                    traducciones.traducciones &&
                    "tel:" + traducciones.traducciones.MASTER.contact_number_link
                  }
                >
                  {traducciones.traducciones && traducciones.traducciones.CONTACTO.contact_number}
                </Link>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: {
                    xs: "center",
                    sm: "center",
                    md: "start",
                    lg: "start",
                    xl: "start",
                  },
                  width: {
                    xs: "auto",
                    sm: "100%",
                  },
                  height: {
                    xs: "auto",
                  },
                  alignItems: "center",
                  gap: ".5rem",
                  marginBottom: "0.5rem",
                  flexWrap: {
                    xs: "wrap",
                    sm: "nowrap",
                  },
                  "& img": {
                    height: {
                      xs: "88px",
                      sm: "auto ",
                    },
                  },
                }}
              >
                <img src={ISO} alt="iso" />
                <img src={Innova} style={{ height: "88px" }} alt="innova" />
                <img style={{ height: "88px" }} src={gPartners} alt="gPartners" />
                <img style={{ height: "88px" }} src={Pci} alt="Pci" />
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3} sx={{ marginBottom: "2rem" }}>
              <Typography sx={{ fontWeight: "bold", fontSize: "19px" }}>
                {traducciones.traducciones && traducciones.traducciones.CONTACTO.address_one_title}
              </Typography>
              <Typography sx={{ fontSize: "12px" }}>
                {traducciones.traducciones &&
                  traducciones.traducciones.CONTACTO.address_one_subtitle}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3} sx={{ marginBottom: "2rem" }}>
              <Typography sx={{ fontWeight: "bold", fontSize: "19px" }}>
                {traducciones.traducciones && traducciones.traducciones.CONTACTO.address_two_title}
              </Typography>
              <Typography sx={{ fontSize: "12px" }}>
                {traducciones.traducciones &&
                  traducciones.traducciones.CONTACTO.address_two_subtitle}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              lg={3}
              xl={3}
              sx={{
                marginBottom: "2rem",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                  gap: ".5rem",
                  marginBottom: "0.5rem",
                }}
              >
                <Link
                  to={traducciones.traducciones && traducciones.traducciones.MASTER.link_twitter}
                  target="_blank"
                  style={{ textDecoration: "none" }}
                >
                  <img src={IconTwitter} alt="twitter" />
                </Link>
                <Link
                  to={traducciones.traducciones && traducciones.traducciones.MASTER.link_linkedin}
                  target="_blank"
                  style={{ textDecoration: "none" }}
                >
                  <img src={IconLinkedin} alt="linkedin" />
                </Link>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                  gap: ".5rem",
                  marginBottom: "0.5rem",
                  marginRight: "-18px",
                }}
              >
                <img src={Angeco} alt="angeco" />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box
        display="flex"
        sx={{
          height: {
            xs: "60px",
            sm: "60px",
            md: "31px",
            lg: "31px",
            xl: "31px",
          },
          backgroundColor: "#A24B5D",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Container sx={{ textAlign: "center" }}>
          <Typography sx={{ "& *": { color: "#FFF!important", fontSize: "12px" } }}>
            <Link to={handleNavigation("cookies")} style={{ textDecoration: "none" }}>
              {traducciones.traducciones && traducciones.traducciones.MASTER.cookies}
            </Link>
            ·
            <Link to={handleNavigation("privacidad")} style={{ textDecoration: "none" }}>
              {traducciones.traducciones && traducciones.traducciones.MASTER.privacy}
            </Link>
            ·
            <Link to={handleNavigation("aviso-legal")} style={{ textDecoration: "none" }}>
              {traducciones.traducciones && traducciones.traducciones.MASTER.legal}
            </Link>
            ·
            <Link
              to={
                traducciones.traducciones ? traducciones.traducciones.MASTER.canal_etico_link : ""
              }
              style={{ textDecoration: "none" }}
            >
              {traducciones.traducciones && traducciones.traducciones.MASTER.ethic}
            </Link>
            ·
            <Link to={handleNavigation("compilance")} style={{ textDecoration: "none" }}>
              {traducciones.traducciones && traducciones.traducciones.MASTER.compliance}
            </Link>
          </Typography>
        </Container>
      </Box>
      {/* {
        <div>
          {!hasCookie && ( // If the cookie is not found, render the iframe
            <iframe
              src={`/cookiePanel/cookie-display.html?api=${encodeURIComponent(
                process.env.REACT_APP_API_BASE_URL
              )}`}
              style={{
                width: "100%",
                height: "100vh",
                position: "fixed",
                top: "0",
                left: "0",
                border: "none",
              }}
              title="Cookie Consent"
            ></iframe>
          )}
        </div>
      } */}
    </>
  );
};
