import React, { useState, useEffect, useContext } from "react";
import Cookies from "js-cookie";
import SeoRoutesContext from "../../../components/shared/SeoRoutesContext";
import { useParams } from "react-router-dom";
import { Grid, Box, Typography, Chip, Container } from "@mui/material";
import ServicioDetailCover from "../../../assets/images/ServicioDetailCover.png";
import { ServiceCardSmall } from "../../../components/ServiceCardSmall";
import HeaderFrontOffice from "../../../components/FrontOfficeHeaders/HeaderFrontOffice";
import { FrontOfficeFooter } from "../../../components/FrontOfficeFooter";
import { TraduccionesContext } from "components/shared/TraduccionesContext";
import { Helmet } from "react-helmet";
import { ContactForm } from "../../../components/ContactForm";
import LoadingScreen from "components/LoadingScreen";
import { useLocation } from "react-router-dom";

function ServiciosDetailPage() {
  const { pathname } = useLocation();
  const [seoParams, setSeoParams] = React.useState(null);
  const [serviceId, setserviceId] = React.useState(null);
  const { traducciones, loading, setLoading, idioma, idiomas } = useContext(TraduccionesContext);
  const seoRoutes = useContext(SeoRoutesContext);
  // const { id: serviceId } = useParams();
  const [services, setServices] = useState([]);
  const [currentServiceLangChanged, setcurrentServiceLangChanged] = useState([]);
  const [currentService, setCurrentService] = useState(null);
  const navigateTo = (routeKey, seoRoutes, lang) => {
    let path = "";
    const seoEntry = seoRoutes.find(
      (seo) =>
        seo.PAGE.replaceAll(".aspx", "").toLowerCase() === routeKey.toLowerCase() &&
        seo.ACRONIMO_ISO === lang
    );
    if (seoEntry) {
      path = `/${seoEntry.ACRONIMO_ISO}/${seoEntry.SEO_URL_FRIENDLY}`;
    } else {
      path = routeKey;
    }
    path = path.replace("//", "/");
    return path;
  };

  const handleNavigation = (key) => {
    const pathSegments = location.pathname.split("/").filter(Boolean);
    const lang = pathSegments[0] || "es"; // Asumir "es" como idioma por defecto si no se encuentra ninguno
    Cookies.set("lang", lang, { expires: 7 });
    const path = navigateTo(key, seoRoutes, lang);
    return path; // Not typically needed unless you have other uses for path after navigation
  };

  // Función para cargar los servicios
  const cargarServicios = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/services/list/${idioma}`,
        {
          method: "GET",
        }
      );
      const data = await response.json();
      setLoading(false);
      setServices(
        data.response.data
          .filter((item) => item.active)
          .sort((a, b) => a.pos - b.pos)
          .map((item) => ({
            title: item.title,
            url: `${window.location.pathname.split("/").filter((segment) => segment !== "")[0]}/${
              item.seoURLFriendly
            }/`,
            // url: `${pathname.substring(0, pathname.lastIndexOf("/"))}/${item.seoURLFriendly}`,
            seoURLFriendly: item.seoURLFriendly,
            // url: `${handleNavigation("servicios")}/${item.id}/${item.seoURLFriendly}`,
          }))
      );

      // Simulamos encontrar un servicio
      // Primero, obtenemos la URL y dividimos en segmentos
      let pathSegments = window.location.pathname.split("/").filter((segment) => segment !== "");

      // Luego, obtenemos el último segmento no vacío
      let seoUrl = pathSegments.pop();

      seoUrl = decodeURIComponent(seoUrl);
      let serviceIt = data.response.data.find(
        (item) => item.active && decodeURIComponent(item.seoURLFriendly) === seoUrl
      );
      if (serviceIt) {
        setserviceId(serviceIt.id);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data: ", error);
    }
  };

  // Función para cargar los detalles de un servicio específico
  const cargarServicio = async () => {
    if (!serviceId) return; // Asegúrate de tener un serviceId válido

    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/services/detail/${serviceId}/${idioma}`,
        {
          method: "GET",
        }
      );
      const data = await response.json();
      setLoading(false);
      console.log(data.response.data);
      var LangItem = idiomas.filter((item) => item.id == idioma)[0];

      setCurrentService({
        ...data.response.data,
        sections: data.response.data.sections.sort((a, b) => a.pos - b.pos),
        keys: data.response.data.keys.sort((a, b) => a.pos - b.pos),
        fullurl:
          location.origin +
          "/" +
          LangItem.label.toLowerCase() +
          "/" +
          data.response.data.seoURLFriendly,
        iso: LangItem.label.toLowerCase(),
      });
      setSeoParams({
        title: data.response.data.seoTitle || "ALTH LOAN",
        keyword: data.response.data.seoMetaKeyword || "",
        description: data.response.data.seoMetaDescription || "",
      });
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data: ", error);
    }

    try {
      var otherLangItem = idiomas.filter((item) => item.id != idioma)[0];
      var otherLang = otherLangItem.id;
      fetch(`${process.env.REACT_APP_API_BASE_URL}/services/detail/${serviceId}/${otherLang}`, {
        method: "GET",
      })
        .then((response) => response.json())
        .then((data) => {
          setcurrentServiceLangChanged({
            ...data.response.data,
            sections: data.response.data.sections.sort((a, b) => a.pos - b.pos),
            keys: data.response.data.keys.sort((a, b) => a.pos - b.pos),
            fullurl:
              location.origin +
              "/" +
              otherLangItem.label.toLowerCase() +
              "/" +
              data.response.data.seoURLFriendly,
            iso: otherLangItem.label.toLowerCase(),
          });
        });
    } catch (error) {
      console.error("Error fetching data: ", error);
    }
  };

  // Efecto para cargar servicios cuando cambia el idioma
  useEffect(() => {
    if (idioma) {
      cargarServicios();
    }
  }, [idioma, pathname]);

  // Efecto para cargar detalles del servicio cuando cambian idioma o serviceId
  useEffect(() => {
    if (serviceId && idioma) {
      cargarServicio();
    }
  }, [serviceId, idioma]);

  if (!currentService) {
    return <LoadingScreen />;
  }

  return (
    <>
      {seoParams && (
        <Helmet>
          <title>{seoParams.title}</title>
          <meta name="description" content={seoParams.description} />
          <meta name="keywords" content={seoParams.keyword} />
          <link rel="canonical" href={`${document.location.href}`} />
          <link rel="alternate" hrefLang={currentService.iso} href={`${currentService.fullurl}`} />
          <link
            rel="alternate"
            hrefLang={currentServiceLangChanged.iso}
            href={`${currentServiceLangChanged.fullurl}`}
          />
        </Helmet>
      )}
      <Box>
        <HeaderFrontOffice
          keySelected="servicios"
          currentPage="serviciosDetail"
          urlLangChanged={currentServiceLangChanged.seoURLFriendly}
        />
      </Box>
      <Box
        sx={{
          marginTop: "64px",
          height: "577px",
          backgroundImage: `url("${
            currentService && currentService.image ? currentService.image : ServicioDetailCover
          }")`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          color: "#FFF",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          "& *": { fontFamily: "Nunito !important" },
        }}
      >
        <Container sx={{ paddingY: "3rem" }}>
          <Box>
            <Typography fontSize="48px" fontWeight={"bold"} textAlign={"center"} component={"h1"}>
              {currentService && currentService.title}
            </Typography>
            <Typography fontSize="18px" textAlign={"center"}>
              {currentService && currentService.subtitle}
            </Typography>
          </Box>
        </Container>
      </Box>
      <Box sx={{ background: "#FFF", color: "#222", "& *": { fontFamily: "Nunito !important" } }}>
        <Container
          sx={{
            paddingTop: "77px",
            paddingBottom: "72px",
            background: "#FFF",
            maxWidth: {
              xl: "1320px!important", // max-width 1320px para xl
              lg: "1140px!important", // max-width 1140px para lg
              md: "100%!important", // max-width 100% para md
              sm: "100%!important", // max-width 100% para sm
              xs: "100%!important", // max-width 100% para xs
            },
          }}
        >
          <Typography fontSize="40px" fontWeight={"bold"} textAlign={"center"} component={"h2"}>
            {currentService && currentService.sections[0] && currentService.sections[0].title}
          </Typography>
          <Typography fontSize="16px" textAlign={"center"} marginBottom={"2rem"}>
            {currentService && currentService.sections[0] && currentService.sections[0].subtitle}
          </Typography>
          {currentService &&
            currentService.sections &&
            currentService.sections.length > 1 &&
            currentService.sections
              .filter((section) => section.active)
              .map((section, index) => (
                <>
                  {index % 2 !== 0 && index > 0 && (
                    <Grid
                      container
                      sx={{
                        marginTop: { xs: "1rem", sm: "1rem", md: "3rem", lg: "3rem", xl: "3rem" },
                      }}
                    >
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        lg={6}
                        xl={6}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Box
                          sx={{
                            maxWidth: "399px",
                            width: { xs: "300px", md: "80%" },
                            height: "auto",
                            aspectRatio: "1",
                            borderRadius: "399px",

                            marginBottom: { xs: "1rem", md: "128px" },
                            backgroundColor: "#EDEDED",
                            backgroundImage: `url("${section.image}")`,
                            backgroundSize: "cover",
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Typography
                          fontSize="24px"
                          fontWeight={"bold"}
                          component={index == 1 && "h3"}
                        >
                          {section.title}
                        </Typography>
                        <Typography fontSize="15px" fontWeight={"bold"} sx={{ color: "#6C6B70" }}>
                          {section.subtitle}
                        </Typography>
                        <Box
                          sx={{
                            maxWidth: { xs: "100%", md: "414px" },
                          }}
                          mt="1.5rem"
                          fontSize={"15px"}
                          lineHeight={"21px"}
                          dangerouslySetInnerHTML={{
                            __html: section.description.replaceAll("\n", "<br/>"),
                          }}
                        />
                      </Grid>
                    </Grid>
                  )}
                  {index % 2 === 0 && index > 0 && (
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        lg={6}
                        xl={6}
                        sx={{
                          order: { xs: "1", md: "0" },
                          justifyContent: { xs: "center", md: "flex-start" },
                          marginBottom: { xs: "50px", md: "0" },
                          paddingLeft: { xs: "0", md: "10%" },
                        }}
                      >
                        <Typography fontSize="24px" fontWeight={"bold"}>
                          {section.title}
                        </Typography>
                        <Typography fontSize="15px" fontWeight={"bold"} sx={{ color: "#6C6B70" }}>
                          {section.subtitle}
                        </Typography>
                        <Box
                          sx={{
                            maxWidth: { xs: "100%", md: "414px" },
                          }}
                          mt="1.5rem"
                          fontSize={"15px"}
                          lineHeight={"21px"}
                          dangerouslySetInnerHTML={{
                            __html: section.description.replaceAll("\n", "<br/>"),
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        lg={6}
                        xl={6}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "start",
                          order: { xs: "0", md: "1" },
                          marginTop: { xs: "50px", md: "0" },
                          justifyContent: { xs: "center", md: "flex-start" },
                        }}
                      >
                        <Box
                          sx={{
                            maxWidth: "399px",
                            width: { xs: "300px", md: "80%" },
                            height: "auto",
                            aspectRatio: "1",
                            borderRadius: "399px",
                            marginY: "3rem",
                            backgroundColor: "#EDEDED",
                            backgroundImage: `url("${section.image}")`,
                            backgroundSize: "cover",
                          }}
                        />
                      </Grid>
                    </Grid>
                  )}
                </>
              ))}

          <Grid container mt={"3rem"}>
            {currentService &&
              currentService.keys &&
              currentService.keys.length > 0 &&
              currentService.keys
                .filter((key) => key.active)
                .map((key) => (
                  <Grid
                    item
                    key={key.id}
                    xs={12}
                    sm={12}
                    md={4}
                    lg={4}
                    xl={4}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginY: "1rem",
                    }}
                  >
                    <Box
                      sx={{
                        width: "330px",
                        height: "240px",
                        border: "1px solid #BABCBD",
                        borderRadius: "9px",
                        padding: "1rem 2rem",
                      }}
                    >
                      <Typography fontSize="21px" fontWeight={"bold"} component={"h3"}>
                        {key.title}
                      </Typography>
                      <Typography fontSize="15px" mt={"3rem"}>
                        {key.subtitle}
                      </Typography>
                    </Box>
                  </Grid>
                ))}
          </Grid>
          <Grid container sx={{ marginY: "4rem", textAlign: "center" }}>
            <Grid item xs={12}>
              <Typography
                gutterBottom
                fontSize={"40px"}
                sx={{ lineHeight: "1", color: "#01101D", fontWeight: "bold" }}
                component={"h2"}
              >
                {traducciones && traducciones.SERVICIO.Mas_soluciones}
              </Typography>
              <Typography fontSize={"16px"} sx={{ color: "#666666" }}>
                {traducciones && traducciones.SERVICIO.Mas_soluciones_text}
              </Typography>
            </Grid>
            <Grid item xs={12} mt={"3rem"}>
              <Grid container rowGap={"1rem"} gap={"1rem"} justifyContent={"center"}>
                {services.map((key) => (
                  <Grid
                    item
                    key={key.title}
                    xs={12}
                    sm={12}
                    md={3}
                    lg={3}
                    xl={3}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <ServiceCardSmall
                      title={key.title}
                      url={key.url}
                      seoURLFriendly={key.seoURLFriendly}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>

            <Grid item xs={12} mt={"5rem"}>
              <Typography
                gutterBottom
                fontSize={"40px"}
                sx={{ lineHeight: "1", color: "#222222", fontWeight: "bold" }}
              >
                {traducciones && traducciones.SERVICIO.demo_form_title}
              </Typography>
              <Typography fontSize={"16px"}>
                {traducciones && traducciones.SERVICIO.demo_form_subtitle}
              </Typography>
            </Grid>

            <Grid item xs={12} mt={"0rem"}>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "2rem",
                }}
              >
                <Box sx={{ width: "408px" }}>
                  <ContactForm rightAligned={false} />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <FrontOfficeFooter />
    </>
  );
}
export default ServiciosDetailPage;
