import React, { useState, useContext, useEffect } from "react";
import Cookies from "js-cookie";
import { useParams, useNavigate, Link, useLocation } from "react-router-dom";
import SeoRoutesContext from "../../../components/shared/SeoRoutesContext";
import { Grid, Box, Typography, Chip, Container } from "@mui/material";
import NewsCover from "../../../assets/images/NewsCover.png";
import { NewsCard } from "../../../components/NewsCard";
import HeaderFrontOffice from "../../../components/FrontOfficeHeaders/HeaderFrontOffice";
import { TraduccionesContext } from "components/shared/TraduccionesContext";
import { FrontOfficeFooter } from "../../../components/FrontOfficeFooter";
import { getImageUrl } from "../../../shared/getImageUrl";
import dayjs from "dayjs";
import { Helmet } from "react-helmet";
import LoadingScreen from "components/LoadingScreen";

function NoticiasDetailPage() {
  const { pathname } = useLocation();
  const [seoParams, setSeoParams] = React.useState(null);
  const [newId, setnewId] = React.useState(null);
  const seoRoutes = useContext(SeoRoutesContext);
  const { traducciones, idioma, setLoading, idiomas } = useContext(TraduccionesContext);
  const [nextUrl, setNextUrl] = useState("");
  const [prevUrl, setPrevUrl] = useState("");
  const [newFound, setNewFound] = useState({});
  const [news, setNews] = useState([]);
  const [newsLangChanged, setnewsLangChanged] = useState([]);
  // const { id: searchParam, seo_url } = useParams();
  const navigate = useNavigate();
  const navigateTo = (routeKey, seoRoutes, lang) => {
    let path = "";
    const seoEntry = seoRoutes.find(
      (seo) =>
        seo.PAGE.replaceAll(".aspx", "").toLowerCase() === routeKey.toLowerCase() &&
        seo.ACRONIMO_ISO === lang
    );
    if (seoEntry) {
      path = `/${seoEntry.ACRONIMO_ISO}/${seoEntry.SEO_URL_FRIENDLY}`;
    } else {
      path = routeKey;
    }
    path = path.replace("//", "/");
    return path;
  };

  const handleNavigation = (key) => {
    const pathSegments = location.pathname.split("/").filter(Boolean);
    const lang = pathSegments[0] || "es"; // Asumir "es" como idioma por defecto si no se encuentra ninguno
    Cookies.set("lang", lang, { expires: 7 });
    const path = navigateTo(key, seoRoutes, lang);
    return path; // Not typically needed unless you have other uses for path after navigation
  };

  function obtenerPathSinUltimoSegmento() {
    // Obtener el pathname actual
    const pathname = window.location.pathname;

    // Dividir el pathname en segmentos
    const segmentos = pathname.split("/");

    // Eliminar el último segmento (elemento vacío si la URL termina en /)
    // Revisa si el último elemento es vacío y elimínalo si es necesario
    if (segmentos[segmentos.length - 1] === "") {
      segmentos.pop(); // Elimina el último segmento vacío si existe
    }

    // Vuelve a eliminar el último segmento ahora que el elemento vacío fue manejado
    segmentos.pop();

    // Unir los segmentos restantes en un string
    const nuevoPathname = segmentos.join("/");

    return nuevoPathname;
  }

  useEffect(() => {
    const cargarNoticias = async () => {
      setLoading(true);
      setNews([]);
      try {
        var LangItem = idiomas.filter((item) => item.id == idioma)[0];
        fetch(`${process.env.REACT_APP_API_BASE_URL}/noticias?lang=${idioma}`, {
          method: "GET",
        })
          .then((response) => response.json())
          .then((data) => {
            setLoading(false);
            const newsMapped = data
              .filter((item) => item.ACTIVO)
              .sort((a, b) => {
                const dateA = dayjs(a.FECHA_PUBLICACION);
                const dateB = dayjs(b.FECHA_PUBLICACION);
                if (dateA.isBefore(dateB)) {
                  return -1;
                }
                if (dateB.isBefore(dateA)) {
                  return 1;
                }
                return 0;
              })
              .map((item) => ({
                id: item.ID,
                title: item.TITULO,
                date: item.FECHA_PUBLICACION,
                content:
                  item.DESC_CORTA.length > 100
                    ? item.DESC_CORTA.slice(0, 100) + "..."
                    : item.DESC_CORTA,
                url: `${obtenerPathSinUltimoSegmento()}/${item.SEO_URL_FRIENDLY}/`,
                // url: `${handleNavigation("noticias")}/${item.ID}/${item.SEO_URL_FRIENDLY}`,
                image: getImageUrl(item.IMAGEN, "noticias"),
                fullContent: item.DESC_LARGA,
                seoTitle: item.SEO_TITLE,
                seoMetaKeyword: item.SEO_METAKEYWORD,
                seoMetaDescription: item.SEO_METADESCRIPTION,
                tags:
                  JSON.parse(item.TAGS) && JSON.parse(item.TAGS).length > 0
                    ? JSON.parse(item.TAGS).map((tag) => tag.NOMBRE)
                    : ["No Tags"],
                fullurl:
                  location.origin +
                  "/" +
                  LangItem.label.toLowerCase() +
                  "/" +
                  item.SEO_URL_FRIENDLY,
                iso: LangItem.label.toLowerCase(),
              }));

            // Primero, obtenemos la URL y dividimos en segmentos
            let pathSegments = window.location.pathname
              .split("/")
              .filter((segment) => segment !== "");

            // Luego, obtenemos el último segmento no vacío
            let seoUrl = pathSegments.pop();

            seoUrl = decodeURIComponent(seoUrl);
            let newsIt = data.find(
              (item) => item.ACTIVO && decodeURIComponent(item.SEO_URL_FRIENDLY) === seoUrl
            );
            const currentNewId = newsIt.ID; //newsMapped.find((item) => item.id === searchParam);
            const currentNew = newsMapped.find((item) => item.id === currentNewId);
            setNewFound(currentNew);
            setSeoParams({
              title: currentNew.seoTitle || "ALTH LOAN",
              keyword: currentNew.seoMetaKeyword || "",
              description: currentNew.seoMetaDescription || "",
            });
            if (newsMapped.indexOf(currentNew) === 0) {
              // Primera noticia
              const newsFiltered = [];
              if (newsMapped[newsMapped.length - 1]) {
                setPrevUrl(`${newsMapped[newsMapped.length - 1].url}`);
                newsFiltered.push(newsMapped[newsMapped.length - 1]);
              }
              if (newsMapped[1]) {
                setNextUrl(`${newsMapped[1].url}`);
                newsFiltered.push(newsMapped[1]);
              }
              if (newsMapped[2]) {
                newsFiltered.push(newsMapped[2]);
              }
              // setNews(newsFiltered);
            } else if (newsMapped.indexOf(currentNew) === newsMapped.length - 1) {
              // Ultima noticia
              const newsFiltered = [];
              if (newsMapped[newsMapped.indexOf(currentNew) - 2])
                newsFiltered.push(newsMapped[newsMapped.indexOf(currentNew) - 2]);
              if (newsMapped[newsMapped.indexOf(currentNew) - 1]) {
                newsFiltered.push(newsMapped[newsMapped.indexOf(currentNew) - 1]);
                setPrevUrl(`${newsMapped[newsMapped.indexOf(currentNew) - 1].url}`);
              }
              if (newsMapped[0]) {
                setNextUrl(`${newsMapped[0].url}`);
                newsFiltered.push(newsMapped[0]);
              }
              // setNews(newsFiltered);
            } else {
              // Tiene anterior y siguiente
              setNextUrl(`${newsMapped[newsMapped.indexOf(currentNew) + 1].url}`);
              setPrevUrl(`${newsMapped[newsMapped.indexOf(currentNew) - 1].url}`);
              const newsFiltered = [];
              if (newsMapped[newsMapped.indexOf(currentNew) - 1])
                newsFiltered.push(newsMapped[newsMapped.indexOf(currentNew) - 1]);
              if (newsMapped[newsMapped.indexOf(currentNew) + 1])
                newsFiltered.push(newsMapped[newsMapped.indexOf(currentNew) + 1]);
              if (newsMapped[newsMapped.indexOf(currentNew) + 2]) {
                newsFiltered.push(newsMapped[newsMapped.indexOf(currentNew) + 2]);
              } else {
                newsFiltered.push(newsMapped[0]);
              }
              // setNews(newsFiltered);
            }

            const newsMappedFiltered = newsMapped.filter((item) => item.id != currentNew.id);
            setNews(newsMappedFiltered);

            var otherLang = idiomas.filter((item) => item.id != idioma)[0].id;

            var otherLangItem = idiomas.filter((item) => item.id != idioma)[0];
            fetch(
              `${process.env.REACT_APP_API_BASE_URL}/noticias/${currentNew.id}?lang=${otherLang}`,
              {
                method: "GET",
              }
            )
              .then((response) => response.json())
              .then((data) => {
                const newsMappedLang = data
                  .filter((item) => item.ACTIVO)
                  .sort((a, b) => {
                    const dateA = dayjs(a.FECHA_PUBLICACION);
                    const dateB = dayjs(b.FECHA_PUBLICACION);
                    if (dateA.isBefore(dateB)) {
                      return -1;
                    }
                    if (dateB.isBefore(dateA)) {
                      return 1;
                    }
                    return 0;
                  })
                  .map((item) => ({
                    id: item.ID,
                    title: item.TITULO,
                    date: item.FECHA_PUBLICACION,
                    content:
                      item.DESC_CORTA.length > 100
                        ? item.DESC_CORTA.slice(0, 100) + "..."
                        : item.DESC_CORTA,
                    url: `${item.SEO_URL_FRIENDLY}`,
                    // url: `${handleNavigation("noticias")}/${item.ID}/${item.SEO_URL_FRIENDLY}`,
                    image: getImageUrl(item.IMAGEN, "noticias"),
                    fullContent: item.DESC_LARGA,
                    seoTitle: item.SEO_TITLE,
                    seoMetaKeyword: item.SEO_METAKEYWORD,
                    seoMetaDescription: item.SEO_METADESCRIPTION,
                    tags:
                      JSON.parse(item.TAGS) && JSON.parse(item.TAGS).length > 0
                        ? JSON.parse(item.TAGS).map((tag) => tag.NOMBRE)
                        : ["No Tags"],
                    fullurl:
                      location.origin +
                      "/" +
                      otherLangItem.label.toLowerCase() +
                      "/" +
                      item.SEO_URL_FRIENDLY,
                    iso: otherLangItem.label.toLowerCase(),
                  }));

                setnewsLangChanged(newsMappedLang[0]);
              });
          })
          .catch((error) => {
            setLoading(false);
            console.error("Error fetching data: ", error);
          });
      } catch (error) {
        setLoading(false);
        console.error("Error al cargar los datos:", error);
      }
    };
    if (idioma) cargarNoticias();
  }, [idioma, pathname]);

  if (!newFound) {
    return <LoadingScreen />;
  }

  return (
    <>
      {seoParams && (
        <Helmet>
          <title>{seoParams.title}</title>
          <meta name="description" content={seoParams.description} />
          <meta name="keywords" content={seoParams.keyword} />
          <link rel="canonical" href={`${document.location.href}`} />
          <link rel="alternate" hrefLang={newFound.iso} href={`${newFound.fullurl}`} />
          <link
            rel="alternate"
            hrefLang={newsLangChanged.iso}
            href={`${newsLangChanged.fullurl}`}
          />
        </Helmet>
      )}
      <Box>
        <HeaderFrontOffice
          keySelected="noticias"
          currentPage="noticiasDetail"
          urlLangChanged={newsLangChanged.url}
        />
      </Box>
      <Box
        sx={{
          marginTop: "64px",
          background: "#FFF",
          "& *": { fontFamily: "Nunito !important" },
        }}
      >
        <Container sx={{ paddingY: "3rem" }}>
          <Grid container sx={{ marginBottom: "1rem" }}>
            <Grid item xs={6}>
              <Link to={prevUrl}>
                <Typography
                  gutterBottom
                  fontSize={"48px"}
                  sx={{
                    lineHeight: "1",
                    color: "#4B5DA2",
                    textDecoration: "underline",
                    fontWeight: "bold",
                    fontSize: "13px",
                    cursor: "pointer",
                  }}
                >
                  {traducciones && `< ${traducciones.NOTICIAS.prev_new}`}
                </Typography>
              </Link>
            </Grid>
            <Grid item xs={6} textAlign={"end"}>
              <Link to={nextUrl}>
                <Typography
                  gutterBottom
                  fontSize={"48px"}
                  sx={{
                    lineHeight: "1",
                    color: "#4B5DA2",
                    textDecoration: "underline",
                    fontWeight: "bold",
                    fontSize: "13px",
                    cursor: "pointer",
                  }}
                >
                  {traducciones && `${traducciones.NOTICIAS.next_new} >`}
                </Typography>
              </Link>
            </Grid>
          </Grid>
          <Grid container sx={{ marginBottom: "0rem", textAlign: "center" }}>
            <Grid item xs={12}>
              <Typography
                gutterBottom
                fontSize={"48px"}
                sx={{ lineHeight: "1", color: "#222222", fontWeight: "bold" }}
                component={"h1"}
              >
                {newFound.title}
              </Typography>
            </Grid>
          </Grid>
          <Grid container sx={{ marginBottom: "2rem", textAlign: "center" }}>
            <Grid item xs={12}>
              <Typography
                gutterBottom
                fontSize={"48px"}
                sx={{ lineHeight: "1", color: "#222222", fontWeight: "bold" }}
              >
                {newFound &&
                  newFound.tags &&
                  newFound.tags.map((tag) => (
                    <Chip
                      key={tag}
                      label={`${tag}`}
                      sx={{
                        marginRight: "1rem",
                        height: "24px",
                        fontSize: "11px",
                        color: "#AAAAAA",
                      }}
                    />
                  ))}
              </Typography>
            </Grid>
          </Grid>
          <Grid container sx={{ marginBottom: "1rem", textAlign: "center" }}>
            <Grid item xs={12}>
              <img
                src={newFound.image}
                alt="new-cover"
                style={{
                  maxWidth: "690px",
                  width: "100%",
                  height: "auto",
                  borderRadius: "16px",
                }}
              />
            </Grid>
          </Grid>
          <Grid container sx={{ marginBottom: "2rem", textAlign: "justify" }}>
            <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
              <Box style={{ width: "690px" }}>
                <Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>
                  {newFound && newFound.date && newFound.date}
                </Typography>
                <Box
                  dangerouslySetInnerHTML={{
                    __html: newFound.fullContent,
                  }}
                />
              </Box>
            </Grid>
          </Grid>
          <Box
            display={{ xs: "none", sm: "none", md: "flex", lg: "flex", xl: "flex" }}
            sx={{
              padding: "2rem",
              paddingBottom: "6rem",
              "& *": { fontFamily: "Nunito !important" },
              background: "#FFF",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              color: "#FFFFFF",
              textAlign: "center",
              justifyContent: "center",
              gap: "2rem",
            }}
          >
            {news.map((noticia) => (
              <Box item key={noticia.title} md={3}>
                <NewsCard
                  title={noticia.title}
                  image={noticia.image}
                  content={noticia.content}
                  url={noticia.url}
                  tags={noticia.tags}
                  handleClickTag={() => {}}
                />
              </Box>
            ))}
          </Box>
        </Container>
      </Box>
      <FrontOfficeFooter />
    </>
  );
}
export default NoticiasDetailPage;
