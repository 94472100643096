import React, { useState, useEffect, useContext } from "react";
import {
  Grid,
  Box,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  Container,
  Chip,
  Button,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { Search } from "@mui/icons-material";
import { NewsCard } from "../../../components/NewsCard";
import HeaderFrontOffice from "../../../components/FrontOfficeHeaders/HeaderFrontOffice";
import { FrontOfficeFooter } from "../../../components/FrontOfficeFooter";
import { TraduccionesContext } from "components/shared/TraduccionesContext";
import { getImageUrl } from "../../../shared/getImageUrl";
import dayjs from "dayjs";
import { BeatLoader } from "react-spinners";
import SeoRoutesContext from "../../../components/shared/SeoRoutesContext";
import { Helmet } from "react-helmet";

function NoticiasPage() {
  const { pathname } = useLocation();
  const [news, setNews] = useState([]);
  const [page, setPage] = useState(0);
  const [totalNoticias, setTotalNoticias] = useState(0);
  const [newsCounter, setNewsCounter] = useState(0);
  const [newsFiltered, setNewsFiltered] = useState([]);
  const [tagsSelected, setTagSelected] = useState([]);
  const [search, setSearch] = useState("");
  const { traducciones, loading, setLoading, idioma } = useContext(TraduccionesContext);
  const seoRoutes = useContext(SeoRoutesContext);

  useEffect(() => {
    const cargarNoticias = async () => {
      setLoading(true);
      try {
        fetch(`${process.env.REACT_APP_API_BASE_URL}/noticias/paginated?lang=${idioma}&page=0`, {
          method: "GET",
        })
          .then((response) => response.json())
          .then((data) => {
            setLoading(false);
            setTotalNoticias(data.totalRecords);
            setNewsCounter(data.data.filter((item) => item.ACTIVO).length);
            setNews(
              data.data
                .filter((item) => item.ACTIVO)
                .sort((a, b) => {
                  const dateA = dayjs(a.FECHA_PUBLICACION);
                  const dateB = dayjs(b.FECHA_PUBLICACION);
                  if (dateA.isBefore(dateB)) {
                    return -1;
                  }
                  if (dateB.isBefore(dateA)) {
                    return 1;
                  }
                  return 0;
                })
                .map((item) => ({
                  id: item.ID,
                  title: item.TITULO,
                  content:
                    item.DESC_CORTA.length > 100
                      ? item.DESC_CORTA.slice(0, 100) + "..."
                      : item.DESC_CORTA,
                  url:
                    window.location.pathname.split("/").filter((segment) => segment !== "")[0] +
                    "/" +
                    item.SEO_URL_FRIENDLY +
                    "/",
                  // url: `${pathname}/${item.ID}/${item.SEO_URL_FRIENDLY}`,
                  // url: `${pathname.substring(0, pathname.lastIndexOf("/"))}/${
                  //   item.SEO_URL_FRIENDLY
                  // }`,

                  image: getImageUrl(item.IMAGEN, "noticias"),
                  tags:
                    JSON.parse(item.TAGS) && JSON.parse(item.TAGS).length > 0
                      ? JSON.parse(item.TAGS).map((tag) => tag.NOMBRE)
                      : ["No Tags"],
                }))
            );
          })
          .catch((error) => {
            setLoading(false);
            console.error("Error fetching data: ", error);
          });
      } catch (error) {
        setLoading(false);
        console.error("Error al cargar los datos:", error);
      }
    };
    if (idioma) {
      setTotalNoticias(0);
      setNewsCounter(0);
      setPage(0);
      setTagSelected([]);
      setSearch("");
      setNews([]);
      setNewsFiltered([]);
      cargarNoticias();
    }
  }, [idioma]);

  useEffect(() => {
    if (search)
      setNewsFiltered([
        ...news.filter((item) => item.title.toLowerCase().includes(search.toLowerCase())),
      ]);
    else setNewsFiltered(news);
  }, [news, search]);

  useEffect(() => {
    setSearch("");
    if (tagsSelected.length > 0) {
      setNewsFiltered(news.filter((item) => item.tags.some((tag) => tagsSelected.includes(tag))));
    } else {
      setNewsFiltered(news);
    }
  }, [tagsSelected]);

  const handleClickTag = (tag) => {
    setTagSelected((prev) => {
      if (prev.includes(tag)) {
        return [...prev];
      } else {
        return [...prev, tag];
      }
    });
  };

  const handleRemoveTag = (tag) => {
    setTagSelected((prev) => prev.filter((selected) => selected !== tag));
  };

  const newsPaginator = async (next, searchPage) => {
    setTagSelected([]);
    setSearch("");
    setPage(searchPage);
    setLoading(true);
    try {
      if (!next) {
        setNewsCounter((prev) => prev - news.length);
      }
      fetch(
        `${process.env.REACT_APP_API_BASE_URL}/noticias/paginated?lang=${idioma}&page=${searchPage}`,
        {
          method: "GET",
        }
      )
        .then((response) => response.json())
        .then((data) => {
          setLoading(false);
          if (next) {
            setNewsCounter((prev) => prev + data.data.filter((item) => item.ACTIVO).length);
          }
          setNews(
            data.data
              .filter((item) => item.ACTIVO)
              .sort((a, b) => {
                const dateA = dayjs(a.FECHA_PUBLICACION);
                const dateB = dayjs(b.FECHA_PUBLICACION);
                if (dateA.isBefore(dateB)) {
                  return -1;
                }
                if (dateB.isBefore(dateA)) {
                  return 1;
                }
                return 0;
              })
              .map((item) => ({
                id: item.ID,
                title: item.TITULO,
                content:
                  item.DESC_CORTA.length > 100
                    ? item.DESC_CORTA.slice(0, 100) + "..."
                    : item.DESC_CORTA,
                url: `${pathname}/${item.ID}/${item.SEO_URL_FRIENDLY}`,
                image: getImageUrl(item.IMAGEN, "noticias"),
                tags:
                  JSON.parse(item.TAGS) && JSON.parse(item.TAGS).length > 0
                    ? JSON.parse(item.TAGS).map((tag) => tag.NOMBRE)
                    : ["No Tags"],
              }))
          );
        })
        .catch((error) => {
          setLoading(false);
          console.error("Error fetching data: ", error);
        });
    } catch (error) {
      setLoading(false);
      console.error("Error al cargar los datos:", error);
    }
  };

  return (
    <>
      <Helmet>
        <link rel="canonical" href={`${document.location.href}`} />
        {seoRoutes
          .filter((x) => x.SEO_PAGE_ID === "6")
          .map(
            (
              lang,
              index // `index` es el índice del elemento actual en el array
            ) => (
              <link
                key={lang.ACRONIMO_ISO + index} // Concatenación del ISO y el índice para asegurar unicidad
                rel="alternate"
                hrefLang={lang.ACRONIMO_ISO.toLowerCase()}
                href={`${document.location.origin}/${lang.ACRONIMO_ISO.toLowerCase()}/${
                  lang.SEO_URL_FRIENDLY.endsWith("/")
                    ? lang.SEO_URL_FRIENDLY
                    : lang.SEO_URL_FRIENDLY + "/"
                }`}
              />
            )
          )}
      </Helmet>
      <Box>
        <HeaderFrontOffice keySelected="noticias" />
      </Box>
      <Box
        sx={{ background: "white", display: "flex", justifyContent: "center", marginTop: "64px" }}
      >
        <Container sx={{ paddingTop: "70px", paddingBottom: "113px" }}>
          <Grid container sx={{ marginBottom: "2rem" }}>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Typography
                gutterBottom
                fontSize={"48px"}
                sx={{ lineHeight: "1", color: "#222222", fontWeight: "bold" }}
              >
                {traducciones && traducciones.NOTICIAS.title}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              {tagsSelected.length > 0 && (
                <>
                  {tagsSelected.map((tag) => (
                    <Chip
                      key={tag}
                      label={`${tag}`}
                      sx={{
                        marginRight: "1rem",
                        height: "24px",
                        fontSize: "11px",
                        color: "#AAAAAA",
                        marginBottom: "12px",
                        marginTop: "23px",
                        cursor: "pointer",
                      }}
                      onClick={() => handleRemoveTag(tag)}
                    />
                  ))}
                </>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <TextField
                fullWidth
                label={traducciones && traducciones.NOTICIAS.search_input_placeholder}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton>
                        <Search sx={{ color: "#222222" }} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          {loading && (
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: `1000px`,
              }}
            >
              <BeatLoader />
            </Box>
          )}
          {!loading && (
            <Grid container rowGap={"2rem"}>
              {newsFiltered.map((noticia, index) => (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  lg={4}
                  xl={3}
                  key={`${noticia.title}-${index}`}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <NewsCard
                    title={noticia.title}
                    image={noticia.image}
                    content={noticia.content}
                    url={noticia.url}
                    tags={noticia.tags}
                    handleClickTag={handleClickTag}
                  />
                </Grid>
              ))}
            </Grid>
          )}
          <Grid container sx={{ marginTop: "2rem" }}>
            <Grid item xs={2}>
              {page > 0 && (
                <Button
                  fullWidth
                  onClick={() => {
                    newsPaginator(false, page - 1);
                    setPage(page - 1);
                  }}
                  sx={{ color: "#A24B5D", display: "flex", justifyContent: "left" }}
                >
                  {traducciones && traducciones.MASTER.prev_text}
                </Button>
              )}
            </Grid>
            <Grid item xs={8} />
            <Grid item xs={2}>
              {newsCounter < totalNoticias && (
                <Button
                  fullWidth
                  onClick={() => {
                    newsPaginator(true, page + 1);
                    setPage(page + 1);
                  }}
                  sx={{ color: "#A24B5D", display: "flex", justifyContent: "right" }}
                >
                  {traducciones && traducciones.MASTER.next_text}
                </Button>
              )}
            </Grid>
          </Grid>
        </Container>
      </Box>
      <FrontOfficeFooter />
    </>
  );
}
export default NoticiasPage;
